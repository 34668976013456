import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const getMyCart = createAsyncThunk(
  'auth/getMyCart',
  async (data, thunkAPI) => {
    return data;
  }
);

export const guestCheckout = createAsyncThunk(
  'cart/guestCheckout',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    try {
      const res = await fetch(`${apiUrl}/public/cart/checkout`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const response = await res.json();

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const userCheckout = createAsyncThunk(
  'cart/userCheckout',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    let callback = () => {};

    if (data.callback) {
      callback = data.callback;

      delete data.callback;
    }

    try {
      const res = await fetch(`${apiUrl}/cart/update`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const response = await res.json();

      console.log('from cart update----', response);

      if (response?.status_code == 200) {
        // Call the callback if provided

        callback(response.data);

        return response?.data;
      } else {
        return thunkAPI.rejectWithValue('Invalid checkout');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const payoutCart = createAsyncThunk(
  'cart/payoutCart',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    let callBackBoc = () => {};

    if (data.callBackBoc) {
      callBackBoc = data.callBackBoc;

      delete data.callBackBoc;
    }

    try {
      const res = await fetch(`${apiUrl}/cart/checkout`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const response = await res.json();

      if (response?.status_code == 201) {
        // Call the callback if provided

        callBackBoc(response?.data);

        return response?.data;
      } else {
        return thunkAPI.rejectWithValue('Invalid pay out');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const sendBocAction = createAsyncThunk(
  'cart/sendBocAction',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    let callback = () => {};

    if (data.callback) {
      callback = data.callback;

      delete data.callback;
    }

    try {
      const res = await fetch(`${apiUrl}/payment/webhook/cello`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const response = await res.json();

      if (response?.status_code == 200) {
        // Call the callback if provided

        callback();

        return response?.data;
      } else {
        return thunkAPI.rejectWithValue('Invalid webhook sent');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    myCart: [],
    authCart: {},
    isLoading: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMyCart.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getMyCart.fulfilled, (state, action) => {
      state.isLoading = false;

      state.myCart = action.payload;
    });
    builder.addCase(getMyCart.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(userCheckout.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(userCheckout.fulfilled, (state, action) => {
      state.isLoading = false;

      state.authCart = action.payload;
    });
    builder.addCase(userCheckout.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(sendBocAction.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(sendBocAction.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(sendBocAction.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default cartSlice.reducer;
