import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Image,
  Stack,
  StackDivider,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Center,
  Grid,
  SimpleGrid,
  Radio,
  extendTheme,
  Flex,
  Card,
  CardBody,
  Spacer,
  useColorMode,
  useColorModeValue,
  Textarea,
  useToast,
  Heading,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';
import { BiCopy } from 'react-icons/bi';

import { useDispatch, useSelector } from 'react-redux';
import { register } from '../redux/slices/auth';
import { getAllCategories } from '../redux/slices/categories';
import { getAllTags } from '../redux/slices/tags';
import {
  createProduct,
  deleteProduct,
  resetCreated,
  updateProduct,
} from '../redux/slices/product';

import UploadSvg from '../assets/svg/upload.svg';
import ArrowSvg from '../assets/svg/arrow-left.svg';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';
import ProductCategorySvg from '../assets/svg/product-category.svg';

import RemoveButtonSvg from '../assets/svg/remove-button.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { savedForm, toggleDarkMode } from '../redux/slices/settings';

import { FaChevronRight } from 'react-icons/fa';
import {
  autoEllipses,
  formatDate,
  formatNumberWithCommas,
} from '../helpers/helpers';
import {
  markOrderAsClosed,
  markOrderAsDelivered,
  markOrderAsShipped,
} from '../redux/slices/orders';

export default function OrderDetails() {
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();

  const text = useColorModeValue('black', 'white');

  const buttonText = useColorModeValue('white', 'black');

  const bg = useColorModeValue('black', 'white');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const startApp = query.get('startapp');

  const authState = useSelector(state => state.auth);
  const productState = useSelector(state => state.product);
  const orderState = useSelector(state => state.order);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(orderState?.singleOrder?.id);

    toast({
      position: 'top-right',
      render: () => (
        <Box color="white" p={3} bg="blue.500">
          Copied
        </Box>
      ),
    });
  };

  console.log(orderState?.singleOrder);

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        bgColor={colorMode == 'light' && 'rgba(238, 238, 238, 0.2)'}
        // pb={{ base: '90px', md: '20px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/store-orders`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>ORDER DETAILS</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Box px={{ base: '20px', md: '20px' }}>
          <Heading
            fontWeight={'600'}
            fontSize={'16px'}
            lineHeight={'18px'}
            my={2}
          >
            Product Details
          </Heading>
          <Card p={4}>
            {(orderState?.singleOrder?.order_items ?? []).length > 0 &&
              orderState?.singleOrder?.order_items.map((item, key) => (
                <Box
                  key={key}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  mb={2}
                >
                  <Text fontSize={'16px'} fontWeight={'400'}>
                    {item?.quantity}x {item?.product_name}
                  </Text>

                  <Text fontSize={'16px'} fontWeight={'600'}>
                    ${item?.product_amount}
                  </Text>
                </Box>
              ))}
          </Card>

          <Heading
            fontWeight={'600'}
            fontSize={'16px'}
            lineHeight={'18px'}
            mb={2}
            mt={8}
          >
            Order Details
          </Heading>
          <Card p={4}>
            <React.Fragment>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Date
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {formatDate(orderState?.singleOrder?.created_at)}
                </Text>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Time
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {new Date(
                    orderState?.singleOrder?.created_at
                  ).toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                  })}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Currency
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {orderState?.singleOrder?.order_currency ?? 'USD'}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Delivery Fee
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {formatNumberWithCommas(
                    orderState?.singleOrder?.order_delivery_fee
                  )}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Discount
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {formatNumberWithCommas(
                    orderState?.singleOrder?.order_discount
                  )}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Insurance Fee
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {formatNumberWithCommas(
                    orderState?.singleOrder?.order_insurance_fee
                  )}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Tax Fee
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {formatNumberWithCommas(
                    orderState?.singleOrder?.order_tax_fee
                  )}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Sub Amount
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {formatNumberWithCommas(
                    orderState?.singleOrder?.order_sub_amount
                  )}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Total
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {formatNumberWithCommas(
                    orderState?.singleOrder?.order_amount
                  )}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Order ID
                </Text>

                <Flex
                  onClick={() => handleCopy()}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Text noOfLines={1} fontSize={'16px'} fontWeight={'600'}>
                    {Object.keys(orderState?.singleOrder).length > 0
                      ? orderState?.singleOrder?.id.slice(0, 15) + '....'
                      : '--'}
                  </Text>
                  <BiCopy size={16} color="rgba(52, 75, 103, 1)" />
                </Flex>
              </Box>
            </React.Fragment>
          </Card>

          <Heading
            fontWeight={'600'}
            fontSize={'16px'}
            lineHeight={'18px'}
            mb={2}
            mt={8}
          >
            Customer Details
          </Heading>
          <Card p={4}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              mb={2}
            >
              <Text fontSize={'16px'} fontWeight={'400'}>
                Name
              </Text>

              <Text fontSize={'16px'} fontWeight={'600'}>
                {autoEllipses(orderState?.singleOrder?.recipient_name)}
              </Text>
            </Box>

            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              mb={2}
            >
              <Text fontSize={'16px'} fontWeight={'400'}>
                Email
              </Text>

              <Text fontSize={'16px'} fontWeight={'600'}>
                {autoEllipses(orderState?.singleOrder?.recipient_email, 400)}
              </Text>
            </Box>

            {orderState?.singleOrder?.recipient_phone &&
              orderState?.singleOrder?.recipient_phone !== 'unspecified' && (
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  mb={2}
                >
                  <Text fontSize={'16px'} fontWeight={'400'}>
                    Phone
                  </Text>

                  <Text fontSize={'16px'} fontWeight={'600'}>
                    {autoEllipses(
                      orderState?.singleOrder?.recipient_phone,
                      400
                    )}
                  </Text>
                </Box>
              )}

            {orderState?.singleOrder?.recipient_address &&
              orderState?.singleOrder?.recipient_address !== 'unspecified' && (
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  mb={2}
                >
                  <Text fontSize={'16px'} fontWeight={'400'}>
                    Address
                  </Text>

                  <Text fontSize={'16px'} fontWeight={'600'}>
                    {autoEllipses(
                      orderState?.singleOrder?.recipient_address,
                      400
                    )}
                  </Text>
                </Box>
              )}

            {orderState?.singleOrder?.recipient_city &&
              orderState?.singleOrder?.recipient_city !== 'unspecified' && (
                <>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      Country
                    </Text>

                    <Text fontSize={'16px'} fontWeight={'600'}>
                      {autoEllipses(
                        orderState?.singleOrder?.recipient_country,
                        400
                      )}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      State
                    </Text>

                    <Text fontSize={'16px'} fontWeight={'600'}>
                      {autoEllipses(
                        orderState?.singleOrder?.recipient_state,
                        400
                      )}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      City
                    </Text>

                    <Text fontSize={'16px'} fontWeight={'600'}>
                      {autoEllipses(
                        orderState?.singleOrder?.recipient_city,
                        400
                      )}
                    </Text>
                  </Box>
                </>
              )}

            {orderState?.singleOrder?.recipient_zip && (
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Zip Code
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {autoEllipses(orderState?.singleOrder?.recipient_zip, 400)}
                </Text>
              </Box>
            )}

            {orderState?.singleOrder?.recipient_address_description &&
              orderState?.singleOrder?.recipient_address_description !==
                'unspecified' && (
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  mb={2}
                >
                  <Text fontSize={'16px'} fontWeight={'400'}>
                    Comment
                  </Text>

                  <Text fontSize={'16px'} fontWeight={'600'}>
                    {autoEllipses(
                      orderState?.singleOrder?.recipient_address_description,
                      400
                    )}
                  </Text>
                </Box>
              )}
          </Card>
        </Box>

        {orderState?.singleOrder &&
          orderState?.singleOrder?.status == 'unshipped' && (
            <Card p={5} mt={10}>
              <Button
                mt={4}
                alignSelf={'center'}
                borderRadius={'16px'}
                width={'100%'}
                height={'54px'}
                backgroundColor={'rgba(215, 226, 244, 1)'}
                isLoading={orderState?.isLoading}
                padding={2}
                onClick={() =>
                  dispatch(
                    markOrderAsClosed({ id: orderState.singleOrder?.id })
                  )
                }
              >
                <Text
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="26px"
                  color={'rgba(36, 107, 253, 1)'}
                >
                  Update status to Closed
                </Text>
              </Button>

              <Button
                mt={4}
                alignSelf={'center'}
                borderRadius={'16px'}
                width={'100%'}
                height={'54px'}
                backgroundColor={'rgba(36, 107, 253, 1)'}
                isLoading={orderState?.isLoading}
                padding={2}
                onClick={() =>
                  dispatch(
                    markOrderAsDelivered({ id: orderState.singleOrder?.id })
                  )
                }
              >
                <Text
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="26px"
                  color={'white'}
                >
                  Update status to Delivered
                </Text>
              </Button>
            </Card>
          )}
      </Flex>
    </>
  );
}
