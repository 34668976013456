import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const getMyRewards = createAsyncThunk(
  'reward/getMyRewards',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;
    try {
      const res = await fetch(`${apiUrl}/reward/me`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,

          'Content-Type': 'application/json',
        },
      });
      const response = await res.json();

      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const claimAReward = createAsyncThunk(
  'reward/claimAReward',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    let callback = () => {};

    if (data?.callback) {
      callback = data.callback;

      delete data.callback;
    }

    try {
      const res = await fetch(`${apiUrl}/reward/claim`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,

          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const response = await res.json();

      if (response?.status_code == 200) {
        callback({});
        return {};
      } else {
        callback(response);
        return thunkAPI.rejectWithValue('Invalid reward claim');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const rewardSlice = createSlice({
  name: 'reward',
  initialState: {
    myRewards: [],
    reward: {},
    reload: false,
    isLoading: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMyRewards.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getMyRewards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.myRewards = action.payload;
    });
    builder.addCase(getMyRewards.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(claimAReward.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(claimAReward.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reward = action.payload;
      state.reload = !state.reload;
    });
    builder.addCase(claimAReward.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default rewardSlice.reducer;
