import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const toggleDarkMode = createAsyncThunk(
  'settings/toggleDarkMode',
  async (data, thunkAPI) => {
    return {};
  }
);

export const savedForm = createAsyncThunk(
  'settings/savedForm',
  async (data, thunkAPI) => {
    return data;
  }
);

export const getApplicationSettings = createAsyncThunk(
  'settings/getApplicationSettings',
  async (data, thunkAPI) => {
    try {
      const res = await fetch(`${apiUrl}/application-setting/show`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const response = await res.json();

      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    isLoading: false,
    darkMode: false,
    form: null,
    applicationSettings: {},
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(toggleDarkMode.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(toggleDarkMode.fulfilled, (state, action) => {
      state.isLoading = false;

      state.darkMode = !state.darkMode;
    });
    builder.addCase(toggleDarkMode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(savedForm.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(savedForm.fulfilled, (state, action) => {
      state.isLoading = false;

      state.form = action.payload;
    });
    builder.addCase(savedForm.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(getApplicationSettings.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getApplicationSettings.fulfilled, (state, action) => {
      state.isLoading = false;

      state.applicationSettings = action.payload;
    });
    builder.addCase(getApplicationSettings.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default settingsSlice.reducer;
