import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Divider,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  CardBody,
  ButtonGroup,
  CardFooter,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  Switch,
  Heading,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  useColorModeValue,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import bot from '../assets/img/bot.jpeg';
import money from '../assets/img/money_wallpaper.jpeg';

import { useDispatch, useSelector } from 'react-redux';
import { register } from '../redux/slices/auth';
import { filterStoreProduct, getMyProducts } from '../redux/slices/product';
import { useParams } from 'react-router-dom';
import { getMyCart } from '../redux/slices/cart';

import UploadSvg from '../assets/svg/upload.svg';
import ArrowSvg from '../assets/svg/arrow-left.svg';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';
import CartSvg from '../assets/svg/cart.svg';
import DarkCartSvg from '../assets/svg/dark-cart.svg';

import ExpandSvg from '../assets/svg/expand.svg';

import { ImCart } from 'react-icons/im';
import {
  getApplicationSettings,
  toggleDarkMode,
} from '../redux/slices/settings';
import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';

import {
  MdClose,
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
} from 'react-icons/md';

export default function Product() {
  const { colorMode, toggleColorMode } = useColorMode();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  const productState = useSelector(state => state.product);

  const cartState = useSelector(state => state.cart);

  const settingsState = useSelector(state => state.settings);

  const [foodMenu, setFoodMenu] = React.useState([]);
  const [viewIndex, setViewIndex] = React.useState(0);

  const { id } = useParams();

  React.useEffect(() => {
    dispatch(
      filterStoreProduct({
        store_id: id,
      })
    );
  }, []);

  React.useEffect(() => {
    dispatch(getApplicationSettings());
  }, []);

  const isSelected = value => {
    const exist = cartState?.myCart.filter(a => a?.id === value?.id);

    if (exist && exist.length > 0) {
      return exist[0];
    }
    return null;
  };

  const addToCart = value => {
    const exist = cartState?.myCart.find(item => item.id === value.id);

    if (exist) {
      const updatedItem = { ...exist, count: (exist?.count ?? 1) + 1 };

      const newCart = cartState.myCart.map(item =>
        item.id === value.id ? updatedItem : item
      );

      dispatch(getMyCart(newCart));
    } else {
      const newItem = [...cartState?.myCart, value];
      dispatch(getMyCart(newItem));
    }
  };

  const minusCategoryItem = value => {
    const exist = cartState?.myCart.find(item => item.id === value.id);

    if (exist) {
      if ((exist?.count ?? 1) === 1) {
        removeCategoryItem(value);
      } else {
        const updatedItem = { ...exist, count: exist.count - 1 };

        const newCart = cartState.myCart.map(item =>
          item.id === value.id ? updatedItem : item
        );

        dispatch(getMyCart(newCart));
      }
    }
  };

  const removeCategoryItem = key => {
    const c = cartState?.myCart.filter(function (e, i) {
      return e?.id !== key?.id;
    });

    dispatch(getMyCart(c));
  };

  const navigateView = direction => {
    if (direction === 'left') {
      setViewIndex(viewIndex =>
        viewIndex === 0
          ? productState?.singleProduct?.merchandise_pictures.length - 1
          : viewIndex - 1
      );
    } else if (direction === 'right') {
      setViewIndex(viewIndex =>
        viewIndex ===
        productState?.singleProduct?.merchandise_pictures.length - 1
          ? 0
          : viewIndex + 1
      );
    }
  };

  const bg = useColorModeValue('white', 'gray.800');

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        pb={{ base: '90px', md: '20px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/store/${id}`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>NOTSTORE</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>
        {}
        <Card
          p="0px"
          gridArea={{ md: '1 / 1 / 2 / 3', '2xl': 'auto' }}
          bgImage={
            (productState?.singleProduct?.merchandise_pictures ?? []).length > 0
              ? productState?.singleProduct?.merchandise_pictures[0]
              : UploadSvg
          }
          h={'300px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          //   bgRepeat="no-repeat"
          bgRepeat="no-repeat"
          bgSize="cover"
          bgPosition="50%"
        >
          <Image
            alignSelf={'flex-end'}
            height={'32px'}
            width={'32px'}
            src={ExpandSvg}
            mt={2}
            mr={3}
            onClick={onOpen}
          />
          <CardBody w="100%" h="100%">
            <Flex
              flexDirection={{ sm: 'column', lg: 'row' }}
              w="100%"
              h="100%"
            ></Flex>
          </CardBody>
          <Box
            backgroundColor={bg}
            height={'10px'}
            bottom={'-1%'}
            width={'100%'}
            borderTopLeftRadius={'10px'}
            borderTopRightRadius={'10px'}
            shadow={0}
            // borderRadius={'10px'}
            position={'absolute'}
          ></Box>
        </Card>

        <Box pt={5} px={{ base: '20px', md: '20px' }}>
          <Heading
            fontWeight={'600'}
            mb={4}
            fontSize={'24px'}
            lineHeight={'24px'}
            size="md"
          >
            {productState?.singleProduct?.merchandise_name ?? '--'}
          </Heading>
          <Heading
            lineHeight={'18px'}
            fontWeight={'600'}
            fontSize={'20px'}
            mb={4}
            size="md"
          >
            {productState?.singleProduct?.merchandise_amount ?? '--'}{' '}
            <span style={{ color: 'gray' }}>
              {settingsState?.applicationSettings?.platform_currency
                ? settingsState?.applicationSettings?.platform_currency
                    ?.alphabetic_code
                : 'USD'}
            </span>
          </Heading>
          <Text
            fontWeight={'400'}
            fontSize={'14px'}
            lineHeight={'19.6px'}
            mb={6}
          >
            {productState?.singleProduct?.merchandise_description ?? '--'}
          </Text>

          <Divider mb={8} />

          <Flex
            alignItems={'center'}
            direction={'row'}
            justifyContent={'space-between'}
          >
            <Box>
              <Text fontSize={'14px'}>
                {isSelected(productState?.singleProduct) ? (
                  <>
                    {parseInt(productState?.singleProduct?.merchandise_amount) *
                      (isSelected(productState?.singleProduct)?.count ?? 1)}
                  </>
                ) : (
                  <>{productState?.singleProduct?.merchandise_amount ?? '--'}</>
                )}

                <span style={{ marginLeft: '5px', color: 'gray' }}>
                  {settingsState?.applicationSettings?.platform_currency
                    ? settingsState?.applicationSettings?.platform_currency
                        ?.alphabetic_code
                    : 'USD'}
                </span>
              </Text>

              {isSelected(productState?.singleProduct) && (
                <Text
                  fontWeight={'400'}
                  fontSize={'10px'}
                  lineHeight={'18px'}
                  color={'rgba(142, 139, 139, 1)'}
                >
                  {`In Cart ${
                    isSelected(productState?.singleProduct)?.count ?? 1
                  } pcs`}
                </Text>
              )}
            </Box>

            {isSelected(productState?.singleProduct) ? (
              <>
                <Flex
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Button
                    onClick={() =>
                      minusCategoryItem(productState?.singleProduct)
                    }
                    textColor={'blue'}
                    variant={'unstyled'}
                  >
                    -
                  </Button>

                  <Text>
                    {isSelected(productState?.singleProduct)?.count ?? 1}
                  </Text>

                  <Button
                    onClick={() => addToCart(productState?.singleProduct)}
                    textColor={'blue'}
                    variant={'unstyled'}
                  >
                    +
                  </Button>

                  <Box
                    alignSelf={'flex-end'}
                    position={'relative'}
                    onClick={() => navigate(`/cart/${id}`)}
                  >
                    {cartState?.myCart.length > 0 && (
                      <>
                        <Center
                          justifyContent={'center'}
                          alignItems={'center'}
                          top={'-15%'}
                          right={'-10%'}
                          position={'absolute'}
                          height={5}
                          width={5}
                          borderRadius={'100%'}
                          bgColor={'wheat'}
                        >
                          <Text color={'teal'} alignSelf={'center'}>
                            {cartState?.myCart.length ?? '0'}
                          </Text>
                        </Center>

                        <Image
                          src={colorMode == 'light' ? CartSvg : DarkCartSvg}
                        />
                      </>
                    )}
                  </Box>
                </Flex>
              </>
            ) : (
              <Button
                alignSelf={'flex-start'}
                h="2.5rem"
                size="sm"
                bgColor={'rgba(36, 107, 253, 1)'}
                onClick={() => {
                  addToCart(productState?.singleProduct);
                }}
                textColor={'white'}
              >
                {'Add to Cart'}
              </Button>
            )}
          </Flex>
        </Box>
      </Flex>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />

        <ModalContent>
          {/* <ModalBody> */}
          <Card
            p="0px"
            gridArea={{ md: '1 / 1 / 2 / 3', '2xl': 'auto' }}
            bgImage={
              (productState?.singleProduct?.merchandise_pictures ?? []).length >
              0
                ? productState?.singleProduct?.merchandise_pictures[viewIndex]
                : UploadSvg
            }
            h={'400px'}
            borderRadius={0}
            //   bgColor={'gray.100'}
            w="100%"
            alignSelf={'center'}
            bgRepeat="no-repeat"
            bgSize="cover"
            bgPosition="50%"
          >
            <Card
              height={'32px'}
              width={'32px'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              alignSelf={'flex-end'}
              bgColor={'rgba(16, 33, 60, 1)'}
              mr={3}
              mt={2}
              onClick={onClose}
            >
              <MdClose size={30} color={'rgba(36, 107, 253, 1)'} />
            </Card>

            {(productState?.singleProduct?.merchandise_pictures ?? []).length >
              1 && (
              <Flex
                display={'flex'}
                flexDirection={'row'}
                mt={'40%'}
                alignSelf={'center'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'90%'}
              >
                <Card
                  height={'32px'}
                  width={'32px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  alignSelf={'flex-start'}
                  bgColor={'rgba(16, 33, 60, 1)'}
                  onClick={() => navigateView('left')}
                >
                  <MdKeyboardArrowLeft
                    size={30}
                    color={'rgba(36, 107, 253, 1)'}
                  />
                </Card>

                <Card
                  height={'32px'}
                  width={'32px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  alignSelf={'flex-end'}
                  bgColor={'rgba(16, 33, 60, 1)'}
                  onClick={() => navigateView('right')}
                >
                  <MdKeyboardArrowRight
                    size={30}
                    color={'rgba(36, 107, 253, 1)'}
                  />
                </Card>
              </Flex>
            )}
          </Card>
          {/* </ModalBody> */}
          {/* <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
}
