import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Heading,
  CardHeader,
  Card,
  CardBody,
  InputRightElement,
  Input,
  InputGroup,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useLocation } from 'react-router-dom';

import { BiArrowBack } from 'react-icons/bi';

import bot from '../assets/img/bot.jpeg';
import money from '../assets/img/money_wallpaper.jpeg';
import humanity from '../assets/img/wallpaper.png';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/slices/auth';
import { getMyStore } from '../redux/slices/store';

export default function BotSetup() {
  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  const navigate = useNavigate();

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="0px"
          gridArea={{ md: '1 / 1 / 2 / 3', '2xl': 'auto' }}
          bgImage={humanity}
          h={'300px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          //   bgRepeat="no-repeat"
          bgRepeat="no-repeat"
          bgSize="cover"
        >
          <CardBody w="100%" h="100%">
            <Flex flexDirection={{ sm: 'column', lg: 'row' }} w="100%" h="100%">
              <Flex
                flexDirection="column"
                h="100%"
                p="22px"
                minW="60%"
                lineHeight="1.6"
              >
                <Text fontSize="sm" color="gray.400" fontWeight="bold">
                  Welcome back,
                </Text>
                <Text fontSize="28px" color="#fff" fontWeight="bold" mb="18px">
                  Mark Johnson
                </Text>

                <Text fontSize="28px" color="#fff" fontWeight="bold" mb="18px">
                  {startParam ?? '--'}
                </Text>
                <Text
                  fontSize="md"
                  color="gray.400"
                  fontWeight="normal"
                  mb="auto"
                >
                  Glad to see you again! <br />
                  Ask me anything.
                </Text>
                <Spacer />
                <Flex align="center">
                  <Button
                    p="0px"
                    variant="no-hover"
                    bg="transparent"
                    my={{ sm: '1.5rem', lg: '0px' }}
                  >
                    <Text
                      fontSize="sm"
                      color="#fff"
                      fontWeight="bold"
                      cursor="pointer"
                      transition="all .3s ease"
                      my={{ sm: '1.5rem', lg: '0px' }}
                      _hover={{ me: '4px' }}
                    >
                      View marketplace
                    </Text>
                    {/* <Icon
											as={BsArrowRight}
											w='20px'
											h='20px'
											color='#fff'
											fontSize='2xl'
											transition='all .3s ease'
											mx='.3rem'
											cursor='pointer'
											pt='4px'
											_hover={{ transform: 'translateX(20%)' }}
										/> */}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <Text px={{ base: '20px', md: '20px' }} pb={'20px'}>
          <Button
            onClick={() => navigate('/')}
            variant="ghost"
            colorScheme="teal"
            mb={'20px'}
          >
            <BiArrowBack style={{ marginRight: '5px' }} /> Back
          </Button>
          <Text fontSize="28px" fontWeight="bold" mb="18px">
            {' '}
            Guide to Integrating Your Store into a Telegram Channel{' '}
          </Text>
          <Text fontSize="28px" fontWeight="bold" mb="18px">
            Step 1: Create a WebApp{' '}
          </Text>
          Open Telegram and search for @BotFather. Send the command /newapp.
          BotFather will show you a list of your bots. Select the one that your
          Ogwugo store is based on.
          <Text fontSize="28px" fontWeight="bold" mb="18px">
            Step 2: Configuring Your WebApp{' '}
          </Text>
          After selecting your bot, BotFather will ask you to enter a name for
          the WebApp. Enter your store's name. Then, you will be asked to enter
          a brief description of the WebApp. Describe your store. BotFather will
          request an image sized 640x360. Send a suitable image (as an image,
          not as a file). You can skip sending a demo GIF by entering the
          command /skip.
          <Text fontSize="28px" fontWeight="bold" mb="18px">
            Step 3: Setting the URL and Completing Setup{' '}
          </Text>
          BotFather will then ask for the website URL. You need to use the URL
          provided by the Ogwugo platform. Copy and send it without any changes.
          Next, when asked for a short name for the WebApp, send app. This is
          crucial for the functioning of your store.
          <Text fontSize="28px" fontWeight="bold" mb="18px">
            Step 4: Integrating into the Channel{' '}
          </Text>
          Add your bot to the Telegram channel as an administrator. After adding
          the bot to the channel, it will automatically integrate and start
          functioning. Note It is important to follow these instructions
          precisely to ensure that your store functions correctly in your
          channel. If you encounter any difficulties, double-check all steps or
          contact support for assistance.
        </Text>
      </Flex>
    </>
  );
}
