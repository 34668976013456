import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const filterMyStoreOrder = createAsyncThunk(
  'order/filterMyStoreOrder',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/store/order/filter/index`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
      },
      body: formData,
    });

    if (res?.status == 204) {
      return {
        current_page: 1,
        data: [],
      };
    }

    const response = await res.json();

    if (response?.status_code == 200) {
      return response?.data;
    }

    return thunkAPI.rejectWithValue('Unsuccessful retrieving orders');
  }
);

export const singleOrder = createAsyncThunk(
  'order/singleOrder',
  async (data, thunkAPI) => {
    return data;
  }
);

export const markOrderAsShipped = createAsyncThunk(
  'order/markOrderAsShipped',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/store/order/ship`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
      },
      body: formData,
    });

    const response = await res.json();

    if (response?.status_code == 200) {
      return response?.data;
    }

    return thunkAPI.rejectWithValue('Unsuccessful updating order');
  }
);

export const markOrderAsClosed = createAsyncThunk(
  'order/markOrderAsClosed',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/store/order/mark/closed`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
      },
      body: formData,
    });

    const response = await res.json();

    if (response?.status_code == 200) {
      return response?.data;
    }

    return thunkAPI.rejectWithValue('Unsuccessful updating order');
  }
);

export const markOrderAsDelivered = createAsyncThunk(
  'order/markOrderAsDelivered',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/store/order/mark/delivered`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
      },
      body: formData,
    });

    const response = await res.json();

    if (response?.status_code == 200) {
      return response?.data;
    }

    return thunkAPI.rejectWithValue('Unsuccessful updating order');
  }
);

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    storeOrder: [],
    singleOrder: {},
    isLoading: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(filterMyStoreOrder.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(filterMyStoreOrder.fulfilled, (state, action) => {
      state.isLoading = false;

      const groups = action.payload.data.reduce((groups, order) => {
        const date = order.created_at.split('T')[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(order);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map(date => {
        return {
          date,
          orders: groups[date],
        };
      });

      if (action.payload?.current_page !== 1) {
        state.storeOrder = {
          ...action.payload,
          data: [...(state.storeOrder?.data ?? []), ...(groupArrays ?? [])],
        };
        return;
      }
      state.storeOrder = {
        data: groupArrays,

        current_page: action?.payload?.current_page,
        last_page: action?.payload?.last_page,
      };
    });
    builder.addCase(filterMyStoreOrder.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(singleOrder.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(singleOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleOrder = action.payload;
    });
    builder.addCase(singleOrder.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(markOrderAsShipped.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(markOrderAsShipped.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleOrder = { ...state.singleOrder, status: 'shipped' };
    });
    builder.addCase(markOrderAsShipped.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(markOrderAsClosed.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(markOrderAsClosed.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleOrder = { ...state.singleOrder, status: 'closed' };
    });
    builder.addCase(markOrderAsClosed.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(markOrderAsDelivered.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(markOrderAsDelivered.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleOrder = { ...state.singleOrder, status: 'delivered' };
    });
    builder.addCase(markOrderAsDelivered.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default orderSlice.reducer;
