import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const getAllTags = createAsyncThunk(
  'auth/getAllTags',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;
    const res = await fetch(`${apiUrl}/product/tag/index`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    const response = await res.json();

    if (response?.status_code == 200) {
      return response?.data?.data;
    }

    return {};
  }
);

export const tagSlice = createSlice({
  name: 'tag',
  initialState: {
    tags: {},
    isLoading: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllTags.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getAllTags.fulfilled, (state, action) => {
      state.isLoading = false;

      state.tags = action.payload;
    });
    builder.addCase(getAllTags.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default tagSlice.reducer;
