import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Heading,
  CardHeader,
  Card,
  CardBody,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  useColorModeValue,
  useColorMode,
  Image,
  Textarea,
  useToast,
} from '@chakra-ui/react';

import { useNavigate, useParams } from 'react-router-dom';

import { BiArrowBack } from 'react-icons/bi';

import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import ArrowSvg from '../assets/svg/arrow-left.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';

import { useDispatch, useSelector } from 'react-redux';
import { login, register } from '../redux/slices/auth';
import { toggleDarkMode } from '../redux/slices/settings';

import { MdKeyboardArrowRight } from 'react-icons/md';

import BigStoreSvg from '../assets/svg/bigStore.svg';

import {
  useTonConnectModal,
  useTonWallet,
  useTonConnectUI,
} from '@tonconnect/ui-react';
import { getMyStore } from '../redux/slices/store';
import { sendMessageWithButton } from '../helpers/telegram';

export default function SwitchAccount() {
  const toast = useToast();

  const { colorMode, toggleColorMode } = useColorMode();

  const { state, open, close } = useTonConnectModal();

  const wallet = useTonWallet();

  const text = useColorModeValue('white', 'gray.800');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  let chatInfo = window.Telegram.WebApp.initDataUnsafe.user;

  const authState = useSelector(state => state.auth);

  const storeState = useSelector(state => state.store);

  const { id } = useParams();

  const [errors, setErrors] = React.useState({});

  return (
    <>
      <Flex
        flexDirection="column"
        height="100vh"
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/store/${id}`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>CREATE STORE</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Center
          px={{ base: '20px', md: '20px' }}
          flexDirection="column"
          mt={'20vh'}
        >
          <Image src={BigStoreSvg} mb={3} />

          <Text fontSize={'24px'} lineHeight={'36px'} mb={6} fontWeight={'500'}>
            {storeState.myStores.length > 0
              ? storeState.myStores[0].store_name
              : '--'}
          </Text>

          <Text
            fontSize={'16px'}
            lineHeight={'24px'}
            fontWeight={'500'}
            textAlign={'center'}
            mb={8}
          >
            {'You already have a store, do you wish to navigate your store?'}
          </Text>
        </Center>

        <Flex
          px={{ base: '20px', md: '20px' }}
          mb={{ base: '40px', md: '40px' }}
          direction={'row'}
          alignSelf={'center'}
          justifyContent={'space-between'}
          width={'60%'}
        >
          <Button
            onClick={() => navigate(`/store/${id}`)}
            isLoading={authState?.isLoading}
            loadingText="Back"
            bgColor={'rgba(16, 33, 60, 1)'}
            color={'rgba(36, 107, 253, 1)'}
            fontSize={'14px'}
            lineHeight={'21px'}
            fontWeight={'500'}
          >
            Back
          </Button>

          <Button
            onClick={() => navigate(`/dashboard`, { replace: true })}
            isLoading={authState?.isLoading}
            loadingText="Submitting"
            bgColor={'rgba(36, 107, 253, 1)'}
            color={'white'}
            fontSize={'14px'}
            lineHeight={'21px'}
            fontWeight={'500'}
          >
            Continue
          </Button>
        </Flex>
      </Flex>
    </>
  );
}
