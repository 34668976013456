import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Image,
  Stack,
  StackDivider,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Center,
  Grid,
  SimpleGrid,
  Radio,
  extendTheme,
  Flex,
  Card,
  CardBody,
  Spacer,
  useColorMode,
  useColorModeValue,
  Textarea,
  useToast,
  Heading,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import { RiFolderUploadLine } from 'react-icons/ri';

import bot from '../assets/img/bot.jpeg';
import money from '../assets/img/money_wallpaper.jpeg';

import { useDispatch, useSelector } from 'react-redux';
import { register } from '../redux/slices/auth';
import {
  createCategories,
  getAllCategories,
  searchCategories,
  selectCategoryAction,
} from '../redux/slices/categories';
import { getAllTags } from '../redux/slices/tags';
import { createProduct, resetCreated } from '../redux/slices/product';

import UploadSvg from '../assets/svg/upload.svg';
import ArrowSvg from '../assets/svg/arrow-left.svg';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';
import ProductCategorySvg from '../assets/svg/product-category.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { toggleDarkMode } from '../redux/slices/settings';

import EmptyCategorySvg from '../assets/svg/empty-category.svg';

import { FaChevronRight } from 'react-icons/fa';
import { ucfirst } from '../helpers/helpers';

export default function SelectCategory() {
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();

  const text = useColorModeValue('black', 'white');

  const buttonText = useColorModeValue('white', 'black');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const startApp = query.get('startapp');

  const [input, setInput] = React.useState({
    name: '',
  });

  return (
    <>
      <Flex
        flexDirection="column"
        // justifyContent={'center'}
        bgColor={colorMode == 'light' && 'rgba(238, 238, 238, 0.2)'}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
        height={'100vh'}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/upload-product`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>ADD CATEGORY</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Box
          display={'flex'}
          flexDirection={'column'}
          px={{ base: '20px', md: '20px' }}
        >
          <Card width={'100%'} alignSelf={'center'} p={3}>
            <Heading
              fontSize={'16px'}
              lineHeight={'22.4px'}
              fontWeight={'500'}
              mb={3}
            >
              Category
            </Heading>

            <Input
              height={'56px'}
              fontSize={'12px'}
              variant="filled"
              placeholder="Enter category name"
              name="name"
              onChange={e => {
                setInput({ ...input, name: e.target.value });
              }}
            />
          </Card>

          <Center position={'absolute'} bottom={0} width={'90%'} marginY={10}>
            <Button
              mt={4}
              borderRadius={'16px'}
              width={'100%'}
              height={'54px'}
              backgroundColor={!input?.name ? 'gray' : 'rgba(36, 107, 253, 1)'}
              padding={2}
              isDisabled={!input?.name}
              onClick={() => {
                dispatch(selectCategoryAction(input?.name));
                navigate(-1);
              }}
            >
              <Text
                fontSize="16px"
                fontWeight="400"
                lineHeight="26px"
                color={input?.name ? 'white' : 'black'}
              >
                {'Create Category'}
              </Text>
            </Button>
          </Center>
        </Box>
      </Flex>
    </>
  );
}
