import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  Heading,
  Avatar,
  Image,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import ArrowSvg from '../assets/svg/arrow-left.svg';

import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import DropdownArrowSvg from '../assets/svg/dropdown-arrow.svg';

import DropupArrowSvg from '../assets/svg/dropup-arrow.svg';

import BlackDropdownArrowSvg from '../assets/svg/blackdropdown.svg';

import BlackDropupArrowSvg from '../assets/svg/blackdropup.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { toggleDarkMode } from '../redux/slices/settings';
import { Player } from '@lottiefiles/react-lottie-player';

export default function FaqPage() {
  const { colorMode, toggleColorMode } = useColorMode();

  const text = useColorModeValue('white', 'gray.800');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const [show, setShow] = React.useState('');

  const allFaq = [
    {
      title: 'How do i set up my store on Notstore?',
      description:
        'To set up your store, start by messaging the Notstore bot and following the setup prompts. You will be asked to provide your store name, contact information, and a description of your store.',
    },
    {
      title: 'How do i add new products to my store?',
      description:
        'To add new products, click on the “Create” button and follow the prompts to enter your product details. Once completed, save the information to list the new product in your store.',
    },
    {
      title: 'How do i update or remove a product?',
      description:
        'To remove a product, search for the desired item, select it, and then click the “Remove” button at the bottom of the page to delete it from your store. If you need to update a product, make the necessary changes to the product details and then click “Save.”',
    },
    {
      title: 'How do i deactivate a product?',
      description:
        'To deactivate a product and make it unavailable, search for the product and turn off the “Availability” button. This will remove it from your product display page.',
    },
    {
      title: 'How are payments processed?',
      description:
        'Payments are processed through the Telegram wallet linked to your account. Customers can pay directly through the bot, and funds will be deposited into your wallet.',
    },
    {
      title: 'Can i track my sales?',
      description:
        'Yes! To track your sales, click on “Order History.” This will display your sales history, including details of each transaction and the total amount earned.',
    },
    {
      title: 'How do i withdraw funds from my Telegram Wallet',
      description:
        'You can withdraw funds by accessing your Telegram wallet and following the withdrawal process. Ensure your bank details are correctly linked to avoid any issues.',
    },
    {
      title: 'How can customers contact me for more inquiries',
      description:
        "Customers can send messages directly to your store through the bot. You'll receive these messages in your Telegram chat and can respond promptly.",
    },
    {
      title: 'Returns or Refunds?',
      description:
        'Establish a return and refund policy and communicate it clearly in your store description. Handle returns and refunds directly with the customer, and update the bot on the status of the transaction.',
    },
    {
      title: 'Technical issues with the Notstore bot?',
      description:
        'To report a technical issue, click on the “Contact Support” button and provide a detailed description of the problem. Our team will assist you promptly.',
    },
    {
      title: 'Improving Visibility and sales for my store',
      description:
        'Promote your store on social media, participate in Telegram groups relevant to your products, and offer promotions or discounts. Regularly updating your product listings and engaging with customers can also help boost sales.',
    },
    {
      title: 'Store and customer data security',
      description:
        'We prioritize security and use encryption to protect your data. Ensure your Telegram account is secured with two-factor authentication and keep your login details confidential.',
    },
    {
      title: 'Suspect Fraudulent activity? do the following...',
      description:
        'If you suspect any fraudulent activity, please report it immediately by clicking on the “Contact Support” button and providing a detailed description of the suspicious activity. Our team will investigate and take appropriate action.',
    },
  ];

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        pb={{ base: '90px', md: '20px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={7}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/dashboard`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>FAQ</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Flex direction={'column'} px={{ base: '20px', md: '20px' }}>
          {allFaq.map((item, index) => (
            <Card
              p={4}
              alignItems={'center'}
              borderRadius={'8px'}
              flexDirection={'column'}
              mb={'10px'}
              onClick={() => (show == index ? setShow('') : setShow(index))}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'row'}
                width={'100%'}
              >
                <Text
                  fontSize={'12px'}
                  lineHeight={'16.8px'}
                  fontWeight={'500'}
                  mb={'4px'}
                >
                  {item?.title}
                </Text>

                <Image
                  src={
                    show == index
                      ? colorMode == 'dark'
                        ? DropdownArrowSvg
                        : BlackDropdownArrowSvg
                      : colorMode == 'dark'
                      ? DropupArrowSvg
                      : BlackDropupArrowSvg
                  }
                  mr={3}
                />
              </Box>

              {show == index && (
                <>
                  <Text fontSize={'9px'} lineHeight={'12.6px'} mb={3} mt={6}>
                    {item?.description}
                  </Text>
                </>
              )}
            </Card>
          ))}
        </Flex>
      </Flex>
    </>
  );
}
