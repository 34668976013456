/**
 * Converts the first letter of a given string to uppercase
 * @param {string} string
 * @return {string}
 */
const ucfirst = string => {
  if (string === undefined) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Ellipse long words and sentences
 * @param {string} string text to be eclipsed.
 * @param {integer} displayAreaWidth current display area width
 * @param {integer} ellipsesPercentage 0-100, percentage of string not to wrap with respect to display area width
 * @return {string}
 */
const autoEllipses = (
  string = '',
  displayAreaWidth = 200,
  ellipsesPercentage = 60
) => {
  let sliceLength =
    (parseInt(displayAreaWidth, 10) / 10) *
    (parseInt(ellipsesPercentage, 10) / 100);
  let suffix = sliceLength < string?.length ? '...' : '';

  return string ? string?.slice(0, sliceLength) + suffix : '--';
};

const formatDate = dateString => {
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getUTCFullYear();

  const ordinalSuffix = day => {
    if (day > 3 && day < 21) return 'th'; // Exceptions for 11th, 12th, 13th
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  return `${day}${ordinalSuffix(day)} ${month} ${year}`;
};

const formatNumberWithCommas = number => {
  return new Intl.NumberFormat('en-US').format(number);
};

const getDateWithTime = sent_date => {
  const date = new Date(sent_date);
  const hours12 = (date.getHours() % 12 || 12).toString().padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Adds leading zero if needed

  return {
    hours12,
    minutes,
    ampm,
  };
};

// Function to validate the date string
const isValidDateString = dateString => {
  const date = new Date(dateString);

  const isValid = !isNaN(date.getTime());

  if (isValid) {
    const isInFuture = date > new Date();
    if (isInFuture) {
      return true;
    }
    return false;
  }
  return false;
};

const generateRandomAlphanumericName = () => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';

  let name = '';

  // Generate 5 random letters
  for (let i = 0; i < 5; i++) {
    name += letters.charAt(Math.floor(Math.random() * letters.length));
  }

  // Generate 2 random numbers
  for (let i = 0; i < 2; i++) {
    name += numbers.charAt(Math.floor(Math.random() * numbers.length));
  }

  // Optionally, shuffle the result to mix letters and numbers
  name = name
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');

  return 'User' + name;
};

export {
  ucfirst,
  formatDate,
  formatNumberWithCommas,
  isValidDateString,
  autoEllipses,
  generateRandomAlphanumericName,
  getDateWithTime,
};
