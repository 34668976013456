import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Divider,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Input,
  InputRightAddon,
  InputGroup,
  Switch,
  Heading,
  Image,
  useColorMode,
  useColorModeValue,
  Select,
  useToast,
  MenuButton,
  Menu,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import bot from '../assets/img/bot.jpeg';
import money from '../assets/img/money_wallpaper.jpeg';

import { useDispatch, useSelector } from 'react-redux';
import { login, register, registerCustomer } from '../redux/slices/auth';
import { filterStoreProduct, getMyProducts } from '../redux/slices/product';
import { useParams } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { sendMessageWithButton } from '../helpers/telegram';

import ArrowSvg from '../assets/svg/arrow-left.svg';

import DropdownArrowSvg from '../assets/svg/dropdown-arrow.svg';

import DropupArrowSvg from '../assets/svg/dropup-arrow.svg';

import BlackDropdownArrowSvg from '../assets/svg/blackdropdown.svg';

import BlackDropupArrowSvg from '../assets/svg/blackdropup.svg';

import EmptyCartSvg from '../assets/svg/empty-cart.svg';

import { MdClose } from 'react-icons/md';

import axios from 'axios';

import {
  useTonConnectModal,
  useTonWallet,
  useTonConnectUI,
} from '@tonconnect/ui-react';
import TonConnect from '@tonconnect/sdk';
import {
  getMyCart,
  payoutCart,
  sendBocAction,
  userCheckout,
} from '../redux/slices/cart';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { toggleDarkMode } from '../redux/slices/settings';
import { getCity, getCountry, getState } from '../redux/slices/location';
import { FaInfoCircle } from 'react-icons/fa';

import { beginCell, Cell } from '@ton/ton';
import { getPaymentProviders } from '../redux/slices/payments';

import TonWeb from 'tonweb';
import { generateRandomAlphanumericName } from '../helpers/helpers';

export default function Checkout() {
  const toast = useToast();

  const tonweb = new TonWeb();

  const { colorMode, toggleColorMode } = useColorMode();

  const bg = useColorModeValue('white', 'gray.800');

  const { state } = useLocation();

  const { open, close } = useTonConnectModal();

  const wallet = useTonWallet();

  const [tonConnectUI, setOptions] = useTonConnectUI();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let chatInfo = window.Telegram.WebApp.initDataUnsafe.user;

  const locationState = useSelector(state => state.location);

  const authState = useSelector(state => state.auth);

  const cartState = useSelector(state => state.cart);

  const storeState = useSelector(state => state.store);

  const paymentState = useSelector(state => state.payment);

  const [walletList, setWalletList] = React.useState([]);

  const [showAddress, setShowAddress] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(true);

  const [input, setInput] = React.useState({});

  const [foodMenu, setFoodMenu] = React.useState([]);
  const [currentPrice, setCurrentPrice] = React.useState('');

  const [isSubmitting, setSubmitting] = React.useState(false);

  const { id } = useParams();

  const calculatePrice = () => {
    const totalPrice = cartState.myCart.reduce((total, item) => {
      return total + parseInt(item?.merchandise_amount) * (item?.count ?? 1);
    }, 0);

    return totalPrice;
  };

  const pay = async responseData => {
    if (!wallet) {
      open();
    }

    const body = beginCell()
      .storeUint(0, 32) // Write 32 zero bits to indicate a text comment will follow
      .storeStringTail(responseData?.id) // Write the text comment
      .endCell();

    const myTransaction = {
      validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
      messages: [
        {
          address: paymentState?.paymentProviders?.cello_wallet,
          //amount: '6485787033',
          amount: (responseData.provider?.cello?.amount * 1e9).toFixed(0),
          //stateInit: body.toBoc().toString('base64'),
          // payload: responseData.id,
          payload: body.toBoc().toString('base64'),
        },
      ],
    };

    try {
      const result = await tonConnectUI.sendTransaction(myTransaction);

      const examplePromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(200);
          dispatch(getMyCart([]));
        }, 2000);
      });

      const bocCell = TonWeb.boc.Cell.oneFromBoc(
        TonWeb.utils.base64ToBytes(result.boc)
      );

      const hash = TonWeb.utils.bytesToBase64(await bocCell.hash());

      // Display the loading toast until the promise is either resolved or rejected
      toast.promise(examplePromise, {
        success: {
          title: 'Transaction successful',
          description: 'Transaction was successful',
        },
        error: {
          title: 'Transaction rejected',
          description: 'Transaction failed',
        },
        loading: { title: 'Transaction pending', description: 'Please wait' },
      });

      await sendMessageWithButton(
        chatInfo.id,
        `Thank you for ordering from ${storeState?.store?.store_name}. Your payment payment number is ${responseData.id}.`
      );

      //   await sendMessageWithButton(chatInfo.id, result.boc);

      dispatch(sendBocAction({ boc: hash, payment_id: responseData.id }));

      setSubmitting(false);

      navigate(`/payment/${id}`);

      // you can use signed boc to find the transaction
      // const someTxData = await myAppExplorerService .getTransaction(result.boc);

      // await sendMessageWithButton(chatInfo.id, JSON.stringify(someTxData));
      //   alert('Transaction ID', result.boc.substring(0, 8));
    } catch (e) {
      console.error(e);
    }

    // window.Telegram.WebApp.close();
  };

  React.useEffect(() => {
    if (cartState.myCart && cartState.myCart?.length == 0) {
      return;
    }
    fetchExchange();
  }, [cartState.myCart]);

  React.useEffect(() => {
    dispatch(getCountry());
    handleLogin();

    if (!showAddress && containPhysical()) {
      setShowAddress(true);
    }
    if (state?.email) {
      setInput({ ...state });
    }
  }, []);

  React.useEffect(() => {
    if (authState.token) {
      setIsLoading(false);
      dispatch(getPaymentProviders());
    }
  }, [authState.token]);

  const getUpdateUserToken = () => {
    if (!chatInfo?.id) {
      return { email: '', password: '', name: '' };
    }
    const input = chatInfo?.id + '-' + chatInfo?.username;

    const result = input.split(/-/);

    //const result = ['2037125694', 'Raphael'];

    const email = 'bot' + result[0] + '@telegram.com';

    const password = result[0] + 'botPass';

    const name = result[1] ? result[1] : generateRandomAlphanumericName();

    return { email, password, name };
  };

  const handleLogin = () => {
    const { email, password, name } = getUpdateUserToken();

    const callback = responseData => {
      if (Object.keys(responseData).length > 0) {
        // toast({
        //   title: 'Account logged in.',
        //   description: JSON.stringify(responseData),
        //   status: 'success',
        //   duration: 2000,
        //   isClosable: true,
        // });

        return;
      } else {
        if (name && email) {
          handleRegister({
            email,
            password,
            password_confirmation: password,
            name,
          });
        }
      }

      // Perform additional actions based on login response
    };

    dispatch(login({ email, password, callback }));
  };

  const handleRegister = registerData => {
    const callback = responseData => {
      if (Object.keys(responseData).length > 0) {
        handleLogin();
        // toast({
        //   title: 'Account created successfully.',
        //   description: JSON.stringify(responseData),
        //   status: 'success',
        //   duration: 2000,
        //   isClosable: true,
        // });

        return;
      }
      alert('No user created');

      // Perform additional actions based on login response
    };

    dispatch(registerCustomer({ ...registerData, callback }));
  };

  const handleCheckout = () => {
    navigate(`/payment-method/${id}`, {
      state: {
        ...input,
      },
    });

    // setSubmitting(true);
    // const products = cartState.myCart.map(item => ({
    //   product_id: item.id,
    //   product_outlet_type: 'store',
    //   product_outlet_id: item?.store_id,
    //   quantity: item.count ?? 1,
    // }));
    // if (authState?.token) {
    //   const callback = responseData => {
    //     const callBackBoc = responseData => {
    //       if (responseData.id) {
    //         navigate(`/payment-method/${id}`, {
    //           state: {
    //             ...responseData,
    //           },
    //         });

    //         //pay(responseData);
    //       }

    //       return;
    //     };
    //     dispatch(
    //       payoutCart({
    //         user_id: authState.user?.id,
    //         ...input,
    //         payment_method: 'cello',
    //         payment_type: 'standard',
    //         recipient_address: 'no man home',
    //         callBackBoc,
    //       })
    //     );
    //     // pay();
    //     return;
    //   };
    //   dispatch(
    //     userCheckout({ products, user_id: authState.user?.id, callback })
    //   );
    // }
  };

  const fetchExchange = async () => {
    const exchange = await axios.get(
      'https://tonapi.io/v2/rates?tokens=ton&currencies=usd'
    );

    if (exchange?.status == 200) {
      setCurrentPrice(exchange?.data?.rates?.TON?.prices['USD']);
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleSelectCountry = e => {
    const selectedOption = e.target.options[e.target.selectedIndex];

    const selectedName = selectedOption.getAttribute('data-name');
    const value = e.target.value;

    setInput({ ...input, country: selectedName });
    dispatch(getState({ country_id: value }));
  };

  const handleSelectState = e => {
    const selectedOption = e.target.options[e.target.selectedIndex];

    const selectedName = selectedOption.getAttribute('data-name');
    const value = e.target.value;
    setInput({ ...input, state: selectedName });
    dispatch(getCity({ state_id: value }));
  };

  const handleSelectCity = e => {
    const selectedOption = e.target.options[e.target.selectedIndex];

    const selectedName = selectedOption.getAttribute('data-name');
    setInput({ ...input, city: selectedName });
  };

  const containPhysical = () => {
    if (cartState.myCart && cartState.myCart?.length > 0) {
      const found = cartState.myCart.some(item => item.format === 'physical');
      return found;
    }

    return false;
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        pb={{ base: '90px', md: '20px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/cart/${id}`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>CHECKOUT</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Box
          px={{ base: '20px', md: '20px' }}
          pb={{ base: '20px', md: '20px' }}
          flexDirection={'column'}
        >
          <Text
            mb={5}
            fontSize={'20px'}
            lineHeight={'26.04px'}
            fontWeight={'700'}
          >
            Contact Details
          </Text>
          <Stack mb={8} spacing={5}>
            <Card width={'100%'}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                px={4}
              >
                <Input
                  height={'56px'}
                  fontSize={'12px'}
                  variant="unstyled"
                  value={input?.name}
                  placeholder="Name"
                  _placeholder={{ color: 'gray.500' }}
                  name="name"
                  onChange={handleChange}
                />
                <Popover>
                  <PopoverTrigger>
                    <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Contact Details Name</PopoverHeader>
                    <PopoverBody fontSize={'12px'}>
                      Enter the name of the person placing the order. This
                      ensures the order is associated with the correct
                      individual.
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
            </Card>

            <Card width={'100%'}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                px={4}
              >
                <Input
                  height={'56px'}
                  variant="unstyled"
                  type="email"
                  fontSize={'12px'}
                  value={input?.email}
                  placeholder="Email address"
                  name="email"
                  _placeholder={{ color: 'gray.500' }}
                  onChange={handleChange}
                />

                <Popover>
                  <PopoverTrigger>
                    <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Contact Details Email</PopoverHeader>
                    <PopoverBody fontSize={'12px'}>
                      Enter an email address to receive order confirmations and
                      updates. Make sure it’s an address you check regularly.
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
            </Card>
          </Stack>

          <Flex
            onClick={() => setShowAddress(!showAddress)}
            my={5}
            justifyContent={'space-between'}
            direction={'row'}
          >
            <Text fontSize={'20px'} lineHeight={'26.04px'} fontWeight={'700'}>
              Delivery Details
            </Text>

            <Image
              src={
                showAddress
                  ? colorMode == 'light'
                    ? BlackDropupArrowSvg
                    : DropupArrowSvg
                  : colorMode == 'light'
                  ? BlackDropdownArrowSvg
                  : DropdownArrowSvg
              }
              mr={3}
            />
          </Flex>
          {showAddress && (
            <Stack spacing={5}>
              <Card width={'100%'}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  px={4}
                >
                  <Input
                    height={'56px'}
                    variant="unstyled"
                    value={input?.phone}
                    fontSize={'12px'}
                    type="number"
                    placeholder="+1234"
                    _placeholder={{ color: 'gray.500' }}
                    name="phone"
                    onChange={handleChange}
                  />
                  <Popover>
                    <PopoverTrigger>
                      <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Contact Details Phone</PopoverHeader>
                      <PopoverBody fontSize={'12px'}>
                        Provide a phone number where you can be reached
                        regarding your order. This helps with communication for
                        delivery or any issues.
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
              </Card>

              <Card width={'100%'}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  px={4}
                >
                  <Input
                    height={'56px'}
                    variant="unstyled"
                    value={input?.address}
                    fontSize={'12px'}
                    required={containPhysical()}
                    type="text"
                    placeholder="street address"
                    _placeholder={{ color: 'gray.500' }}
                    name="address"
                    onChange={handleChange}
                  />
                  <Popover>
                    <PopoverTrigger>
                      <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Contact Details Address</PopoverHeader>
                      <PopoverBody fontSize={'12px'}>
                        Provide a street address where you can be reached
                        regarding your order. This helps with accurate delivery
                        or any issues.
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
              </Card>

              <Card>
                <Select
                  height={'56px'}
                  iconSize="10px"
                  fontSize={'12px'}
                  icon={
                    <Image
                      src={
                        colorMode == 'light'
                          ? BlackDropdownArrowSvg
                          : DropdownArrowSvg
                      }
                    />
                  }
                  placeholder="Select Country"
                  _placeholder={{ color: 'gray.500' }}
                  variant="filled"
                  onChange={handleSelectCountry}
                >
                  {locationState?.country &&
                    locationState?.country.length > 0 &&
                    locationState?.country.map((item, index) => (
                      <option
                        data-name={item?.name}
                        key={index}
                        value={item?.id}
                      >
                        {item?.name}
                      </option>
                    ))}
                </Select>
              </Card>

              <Card>
                <Select
                  height={'56px'}
                  iconSize="10px"
                  icon={
                    <Image
                      src={
                        colorMode == 'light'
                          ? BlackDropdownArrowSvg
                          : DropdownArrowSvg
                      }
                    />
                  }
                  placeholder="Select State"
                  _placeholder={{ color: 'gray.500' }}
                  fontSize={'12px'}
                  variant="filled"
                  onChange={handleSelectState}
                >
                  {locationState?.state &&
                    locationState?.state.length > 0 &&
                    locationState?.state.map((item, index) => (
                      <option
                        data-name={item?.name}
                        key={index}
                        value={item?.id}
                      >
                        {item?.name}
                      </option>
                    ))}
                </Select>
              </Card>

              <Card>
                <Select
                  height={'56px'}
                  iconSize="10px"
                  fontSize={'12px'}
                  icon={
                    <Image
                      src={
                        colorMode == 'light'
                          ? BlackDropdownArrowSvg
                          : DropdownArrowSvg
                      }
                    />
                  }
                  placeholder="Select City"
                  _placeholder={{ color: 'gray.500' }}
                  variant="filled"
                  onChange={handleSelectCity}
                >
                  {locationState?.city &&
                    locationState?.city.length > 0 &&
                    locationState?.city.map((item, index) => (
                      <option
                        data-name={item?.name}
                        key={index}
                        value={item?.id}
                      >
                        {item?.name}
                      </option>
                    ))}
                </Select>
              </Card>

              <Card width={'100%'}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  px={4}
                >
                  <Input
                    height={'56px'}
                    variant="unstyled"
                    fontSize={'12px'}
                    value={input?.zip}
                    type="number"
                    placeholder="ZIP Code"
                    _placeholder={{ color: 'gray.500' }}
                    name="zip"
                    onChange={handleChange}
                  />
                  <Popover>
                    <PopoverTrigger>
                      <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>ZIP Code</PopoverHeader>
                      <PopoverBody fontSize={'12px'}>
                        Provide a zip code that corresponds to your location.
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
              </Card>

              <Textarea
                type="text"
                variant="filled"
                noOfLines={4}
                name="description"
                height={'50px'}
                // width={{ sm: '328px', md: '380px' }}
                placeholder="Enter comment for seller"
                _placeholder={{ color: 'gray.500' }}
                value={input?.description}
                onChange={handleChange}
              />
            </Stack>
          )}
          {containPhysical() && (
            <Flex mt={2} alignItems={'center'} flexDirection={'row'}>
              <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
              <Text
                fontSize={'10px'}
                lineHeight={'10px'}
                fontWeight={'400'}
                color={'rgba(108, 117, 125, 1)'}
                ml={2}
              >
                If cart contains physical item, delivery details are compulsory.
              </Text>
            </Flex>
          )}

          <Flex
            py={7}
            flexDirection={'column'}
            position={'fixed'}
            bottom={0}
            width={'100%'}
            bgColor={bg}
          >
            <Flex
              alignItems={'center'}
              direction={'row'}
              justifyContent={'space-between'}
              pr={10}
            >
              <Box>
                <Text lineHeight={'18px'} fontSize={'14px'}>
                  {calculatePrice()}

                  <span style={{ marginLeft: '5px', color: 'gray' }}>USD</span>
                </Text>

                {currentPrice && (
                  <Text
                    fontWeight={'400'}
                    fontSize={'10px'}
                    lineHeight={'18px'}
                    color={'rgba(142, 139, 139, 1)'}
                  >
                    {`${currentPrice.toFixed(2)} USD = 1 TON`}
                  </Text>
                )}
              </Box>

              <Button
                alignSelf={'flex-start'}
                h="2.5rem"
                borderRadius={'8px'}
                width={'100px'}
                size="sm"
                isDisabled={
                  !input?.name ||
                  !input?.email ||
                  (containPhysical() && !input?.address) ||
                  isSubmitting
                }
                bgColor={
                  !input?.name || !input?.email || isSubmitting
                    ? 'gray'
                    : 'rgba(36, 107, 253, 1)'
                }
                textColor={'white'}
                isLoading={isLoading || isSubmitting}
                loadingText="waiting.."
                onClick={() => handleCheckout()}
              >
                {'Pay Now'}
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}
