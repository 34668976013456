import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Image,
  Stack,
  StackDivider,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Center,
  Grid,
  SimpleGrid,
  Radio,
  extendTheme,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Flex,
  Card,
  CardBody,
  Spacer,
  useColorMode,
  useColorModeValue,
  Textarea,
  useToast,
  Select,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import { RiFolderUploadLine } from 'react-icons/ri';

import bot from '../assets/img/bot.jpeg';
import money from '../assets/img/money_wallpaper.jpeg';

import { useDispatch, useSelector } from 'react-redux';
import { register } from '../redux/slices/auth';
import {
  getAllCategories,
  selectCategoryAction,
} from '../redux/slices/categories';
import { getAllTags } from '../redux/slices/tags';
import { createProduct, resetCreated } from '../redux/slices/product';

import UploadSvg from '../assets/svg/upload.svg';
import ArrowSvg from '../assets/svg/arrow-left.svg';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';
import ProductCategorySvg from '../assets/svg/product-category.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import {
  getApplicationSettings,
  savedForm,
  toggleDarkMode,
} from '../redux/slices/settings';
import { FaInfoCircle } from 'react-icons/fa';

import DropdownArrowSvg from '../assets/svg/dropdown-arrow.svg';

import DropupArrowSvg from '../assets/svg/dropup-arrow.svg';

import BlackDropdownArrowSvg from '../assets/svg/blackdropdown.svg';

import BlackDropupArrowSvg from '../assets/svg/blackdropup.svg';

import { FaChevronRight } from 'react-icons/fa';

export default function UploadProduct() {
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();

  const text = useColorModeValue('black', 'white');

  const buttonText = useColorModeValue('white', 'black');

  const bg = useColorModeValue('black', 'white');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const startApp = query.get('startapp');

  const authState = useSelector(state => state.auth);
  const productState = useSelector(state => state.product);
  const categoryState = useSelector(state => state.category);
  const tagState = useSelector(state => state.tag);
  const storeState = useSelector(state => state.store);

  const settingsState = useSelector(state => state.settings);

  const [input, setInput] = React.useState({
    merchandise_name: '',
    merchandise_description: '',
    merchandise_amount: '',
    format: 'physical',
  });

  React.useEffect(() => {
    if (categoryState?.selectedCategory) {
      let category = [];
      category.push(categoryState?.selectedCategory);
      setInput({ ...input, merchandise_categories: category });
    }

    if (settingsState?.form) {
      const {
        merchandise_name,
        merchandise_description,
        merchandise_amount,
        format,
        base64_photos,
        imageUrl,
      } = settingsState?.form;

      if (categoryState?.selectedCategory) {
        let category = [];
        category.push(categoryState?.selectedCategory);
        setInput({
          ...input,
          merchandise_amount,
          merchandise_name,
          merchandise_description,
          format,
          merchandise_categories: category,
        });
      }

      setImageUrl(imageUrl);
      setBase64(base64_photos);

      dispatch(savedForm(null));
    }
  }, [categoryState?.selectedCategory]);

  React.useEffect(() => {
    if (productState?.created) {
      setTimeout(() => {
        dispatch(resetCreated());

        toast({
          position: 'top-right',
          render: () => (
            <Box color="white" p={3} bg="blue.500">
              Created successfully
            </Box>
          ),
        });

        navigate('/products');
      }, 1000);
    }
  }, [productState?.reload]);

  React.useEffect(() => {
    if (productState?.error) {
      toast({
        position: 'top-right',
        render: () => (
          <Box color="white" p={3} bg="red.500">
            {productState?.error ?? 'An error occurred creating product'}
          </Box>
        ),
      });
    }
  }, [productState?.error]);

  React.useEffect(() => {
    dispatch(getApplicationSettings());
  }, []);

  const [imageUrl, setImageUrl] = React.useState([null, null, null]);

  const [base64_photos, setBase64] = React.useState([null, null, null]);

  const [errors, setErrors] = React.useState({});

  const validateForm = () => {
    let formErrors = {};

    if (
      input.merchandise_name.length < 2 ||
      input.merchandise_name.length > 60
    ) {
      formErrors.merchandise_name =
        'name is required and length greater than 2 and less than 60';
    }

    if (
      input.merchandise_description.length < 10 ||
      input.merchandise_description.length > 500
    ) {
      formErrors.merchandise_description =
        'Description is required and length greater than 10 and less than 500';
    }

    if (input.merchandise_amount < 0) {
      formErrors.merchandise_amount =
        'Amount is required and length greater than 2 and less than 60';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const validateImage = file => {
    return new Promise((resolve, reject) => {
      // Check if file is a jpeg/jpg image
      const acceptedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!acceptedFormats.includes(file.type)) {
        reject('Image format must be jpeg/jpg/png.');
        return;
      }

      // Check if file size is not greater than 5 MB
      const maxSize = 1.8 * 1024 * 1024; // 5 MB in bytes
      if (file.size > maxSize) {
        reject('Image size must not be greater than 2 MB.');
        return;
      }

      // Create an image object to get image dimensions
      const image = new window.Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        console.log(image.height, image.width);
        // Check if image dimensions do not exceed 200x200px
        if (image.width == 700 || image.height == 700) {
          reject('Maximum size of photo must be less than 700x700px.');
        } else {
          resolve();
        }
      };

      image.onerror = () => {
        reject('Failed to load image.');
      };
    });
  };

  const updateImageUrl = (index, url, base64) => {
    setImageUrl(prevUrls => {
      const newUrls = [...prevUrls];
      newUrls[index] = url;
      return newUrls;
    });

    setBase64(prevUrls => {
      const newUrls = [...prevUrls];
      newUrls[index] = base64;
      return newUrls;
    });
  };

  const handleFileChange = (event, index) => {
    const selectedFile = event.target.files[0];

    validateImage(selectedFile)
      .then(() => {
        setInput({ ...input, photo: selectedFile });
        const url = URL.createObjectURL(selectedFile);

        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
          updateImageUrl(index, url, reader.result);
        };
        reader.onerror = error => {
          console.error('Error reading file:', error);
        };

        setErrors({ ...errors, photo: '' });
      })
      .catch(error => {
        setErrors({ ...errors, photo: error });
        // Display error message to the user
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (validateForm()) {
      dispatch(savedForm(null));
      dispatch(selectCategoryAction(''));

      const validUpload = base64_photos.filter(item => item !== null);

      dispatch(
        createProduct({
          ...input,

          base64_photos: validUpload,
          business_id: authState?.team,
          store_id: storeState?.myStores[0]?.id,
        })
      );
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleSelectProductType = e => {
    const selectedOption = e.target.options[e.target.selectedIndex];

    const selectedName = selectedOption.getAttribute('data-name');

    setInput({ ...input, format: selectedName });
  };

  const saveCurrentForm = () => {
    dispatch(
      savedForm({
        ...input,
        imageUrl,
        base64_photos,
      })
    );
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        bgColor={colorMode == 'light' && 'rgba(238, 238, 238, 0.2)'}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => {
                dispatch(selectCategoryAction(''));
                navigate(`/dashboard`);
              }}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>CREATE PRODUCT</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <form onSubmit={handleSubmit}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            px={{ base: '20px', md: '20px' }}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'25%'}
              marginBottom={4}
            >
              <Text
                fontSize="16px"
                fontWeight="500"
                lineHeight="22.4px"
                color={text}
              >
                Images
              </Text>
              <Popover>
                <PopoverTrigger>
                  <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Product Image</PopoverHeader>
                  <PopoverBody fontSize={'12px'}>
                    Upload a high-quality image of your product. The image
                    should be at least 200x200 pixels and no larger than 2MB in
                    size. Only jpg, jpeg, png, gif and bmp formats are
                    supported.
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
            <Grid
              templateColumns={{ base: 'repeat(3, 1fr)', md: 'repeat(3, 1fr)' }} // 1 column on mobile, 3 columns on medium screens and above
              gap={{ base: 1, md: 8 }} // Spacing between items, 4 on mobile, 8 on medium screens and above
              // Other grid properties as needed
              // pt={{ base: '30px', md: '20px' }}
              // px={{ base: '10px', md: '20px' }}
              // pb={{ base: '30px', md: '20px' }}
              alignSelf={'center'}
            >
              <Box
                minH={'136px'}
                minW={'120px'}
                bgImage={imageUrl[0] ?? UploadSvg}
                bgRepeat={'no-repeat'}
                bgSize="contain"
                bgPosition="100%"
              >
                <Input
                  accept="image/*"
                  style={{
                    display: 'none',
                    height: '136px',
                    width: '120px',
                  }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={e => handleFileChange(e, 0)}
                />
                <label
                  htmlFor="contained-button-file"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                ></label>
              </Box>

              <Box
                minH={'136px'}
                minW={'120px'}
                bgImage={imageUrl[1] ?? UploadSvg}
                bgRepeat={'no-repeat'}
                bgSize="contain"
                bgPosition="100%"
              >
                <Input
                  accept="image/*"
                  style={{
                    display: 'none',
                    height: '136px',
                    width: '120px',
                  }}
                  id="contained-button-file-one"
                  multiple
                  type="file"
                  onChange={e => handleFileChange(e, 1)}
                />
                <label
                  htmlFor="contained-button-file-one"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                ></label>
              </Box>

              <Box
                minH={'136px'}
                minW={'120px'}
                bgImage={imageUrl[2] ?? UploadSvg}
                bgRepeat={'no-repeat'}
                bgSize="contain"
                bgPosition="100%"
              >
                <Input
                  accept="image/*"
                  style={{
                    display: 'none',
                    height: '136px',
                    width: '120px',
                  }}
                  id="contained-button-file-two"
                  multiple
                  type="file"
                  onChange={e => handleFileChange(e, 2)}
                />
                <label
                  htmlFor="contained-button-file-two"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                ></label>
              </Box>
            </Grid>
            {errors?.photo && (
              <Text
                fontSize="12px"
                fontWeight="400"
                lineHeight="14px"
                textAlign={'start'}
                color="red.500"
                marginBottom={'10px'}
                mt={'5px'}
              >
                {errors.photo}
              </Text>
            )}

            {/* <Text
              fontSize="20px"
              fontWeight="300"
              lineHeight="26px"
              color={text}
              marginTop={10}
              marginBottom={4}
            >
              GENERAL
            </Text> */}

            <Stack marginTop={10} spacing="4">
              <Card width={'100%'}>
                <FormControl
                  id="merchandise_name"
                  isInvalid={errors.merchandise_name}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    pr={4}
                  >
                    <Input
                      type="text"
                      height={'56px'}
                      width={'100%'}
                      pl={5}
                      variant="unstyled"
                      fontSize={'12px'}
                      lineHeight={'18px'}
                      fontWeight={'500'}
                      name="merchandise_name"
                      borderColor={'rgba(208, 207, 207, 1)'}
                      // width={{ sm: '330px', md: '382px' }}
                      value={input.merchandise_name}
                      placeholder="product name"
                      _placeholder={{ color: 'gray.500' }}
                      onChange={handleChange}
                    />
                    <Popover>
                      <PopoverTrigger>
                        <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Product Name</PopoverHeader>
                        <PopoverBody fontSize={'12px'}>
                          Choose a name for your product that clearly identifies
                          what it is. Make it unique, descriptive, and easy for
                          customers to remember.
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Box>
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
              </Card>

              <Card>
                <FormControl
                  id="merchandise_amount"
                  isInvalid={errors.merchandise_amount}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    pr={4}
                  >
                    <Input
                      type="number"
                      height={'56px'}
                      width={'100%'}
                      pl={5}
                      variant="unstyled"
                      fontSize={'12px'}
                      lineHeight={'18px'}
                      fontWeight={'500'}
                      name="merchandise_amount"
                      borderColor={'rgba(208, 207, 207, 1)'}
                      // width={{ sm: '340px', md: '382px' }}
                      placeholder={`Price (${
                        settingsState?.applicationSettings?.platform_currency
                          ? settingsState?.applicationSettings
                              ?.platform_currency?.alphabetic_code
                          : 'USD'
                      })`}
                      _placeholder={{ color: 'gray.500' }}
                      value={input.merchandise_amount}
                      onChange={handleChange}
                    />

                    <Popover>
                      <PopoverTrigger>
                        <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Product Price</PopoverHeader>
                        <PopoverBody fontSize={'12px'}>
                          Set the price of your product. Ensure it reflects the
                          value of the item and is competitive within your
                          market. The currency is{' '}
                          {settingsState?.applicationSettings?.platform_currency
                            ? settingsState?.applicationSettings
                                ?.platform_currency?.alphabetic_code
                            : 'USD'}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Box>

                  <FormErrorMessage>
                    {errors.merchandise_amount}
                  </FormErrorMessage>
                </FormControl>
              </Card>

              <Card pl={5}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  pr={4}
                >
                  <Select
                    height={'56px'}
                    iconSize="10px"
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    fontWeight={'500'}
                    color={'gray.500'}
                    placeholder="Product Type"
                    variant="unstyled"
                    onChange={handleSelectProductType}
                  >
                    <option
                      selected={input?.format == 'physical'}
                      data-name={'physical'}
                      value={'physical'}
                    >
                      {'Physical'}
                    </option>

                    <option
                      selected={input?.format == 'digital'}
                      data-name={'digital'}
                      value={'digital'}
                    >
                      {'Digital'}
                    </option>
                  </Select>
                  <Popover>
                    <PopoverTrigger>
                      <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Product Type</PopoverHeader>
                      <PopoverBody fontSize={'12px'}>
                        Select whether your product is a physical item that
                        needs shipping or a digital product that can be
                        downloaded.
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
              </Card>

              <Card width={'100%'}>
                <CardBody>
                  <FormControl
                    marginBottom={10}
                    id="merchandise_description"
                    isInvalid={errors.merchandise_description}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      // alignItems={'center'}
                      // pr={4}
                    >
                      <Textarea
                        type="text"
                        pr={2}
                        variant="unstyled"
                        noOfLines={4}
                        fontSize={'12px'}
                        lineHeight={'18px'}
                        fontWeight={'500'}
                        name="merchandise_description"
                        borderWidth={0}
                        borderColor={'rgba(208, 207, 207, 1)'}
                        height={'50px'}
                        width={{ sm: '328px', md: '380px' }}
                        placeholder="Enter product description"
                        _placeholder={{ color: 'gray.500' }}
                        value={input.merchandise_description}
                        onChange={handleChange}
                      />
                      <Popover>
                        <PopoverTrigger>
                          <FaInfoCircle
                            style={{ marginTop: '3%' }}
                            color={'rgba(108, 117, 125, 1)'}
                          />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>Product Description</PopoverHeader>
                          <PopoverBody fontSize={'12px'}>
                            Provide a detailed description of your product.
                            Highlight key features, benefits, and any important
                            information customers should know before purchasing.
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Box>
                    <FormErrorMessage>
                      {errors.merchandise_description}
                    </FormErrorMessage>
                  </FormControl>
                </CardBody>
              </Card>
            </Stack>

            <Card
              mt={4}
              p={3}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              onClick={() => {
                saveCurrentForm();
                navigate('/select-category');
              }}
            >
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Image src={ProductCategorySvg} mr={3} />
                <Text
                  fontSize={'12px'}
                  lineHeight={'18px'}
                  fontWeight={'500'}
                  color={
                    colorMode == 'light' ? 'rgba(52, 75, 103, 1)' : 'white'
                  }
                >
                  Category{' '}
                  {input?.merchandise_categories
                    ? `: ${input?.merchandise_categories[0]}`
                    : ''}
                </Text>
              </Box>

              <FaChevronRight />
            </Card>

            {/* <Card width={'100%'}>
              <CardBody>
                <Grid
                  templateColumns={{
                    base: 'repeat(3, 1fr)',
                    md: 'repeat(3, 1fr)',
                  }} // 1 column on mobile, 3 columns on medium screens and above
                  gap={{ base: 4, md: 8 }} // Spacing between items, 4 on mobile, 8 on medium screens and above
                  marginBottom={4}
                >
                  {categoryState?.categories &&
                    categoryState.categories.length > 0 &&
                    categoryState.categories.map((position, i) => (
                      <Radio
                        key={i}
                        size="md"
                        name={'position_id'}
                        value={position?.name}
                        isChecked={isSelected(position?.name)}
                        onClick={() => {
                          select(position?.name);
                        }}
                        // onChange={select}
                      >
                        <Text
                          fontSize="16px"
                          fontWeight="400"
                          lineHeight="26px"
                        >
                          {position?.name}
                        </Text>
                      </Radio>
                    ))}
                </Grid>
              </CardBody>
            </Card> */}

            {/* <Card width={'100%'}>
              <CardBody>
                <Grid
                  templateColumns={{
                    base: 'repeat(3, 1fr)',
                    md: 'repeat(3, 1fr)',
                  }} // 1 column on mobile, 3 columns on medium screens and above
                  gap={{ base: 4, md: 8 }} // Spacing between items, 4 on mobile, 8 on medium screens and above
                  marginBottom={4}
                >
                  {tagState?.tags &&
                    tagState.tags.length > 0 &&
                    tagState.tags.map((position, i) => (
                      <Radio
                        key={i}
                        size="md"
                        name={'position_id'}
                        value={position?.name}
                        isChecked={isSelectedTag(position?.name)}
                        onClick={() => {
                          selectTag(position?.name);
                        }}
                      >
                        <Text
                          fontSize="16px"
                          fontWeight="400"
                          lineHeight="26px"
                        >
                          {position?.name}
                        </Text>
                      </Radio>
                    ))}
                </Grid>
              </CardBody>
            </Card> */}

            <Center width={'100%'} marginY={10}>
              <Button
                mt={4}
                alignSelf={'center'}
                borderRadius={'16px'}
                width={'100%'}
                height={'54px'}
                backgroundColor={
                  !input?.merchandise_name || !base64_photos[0]
                    ? 'gray'
                    : 'rgba(36, 107, 253, 1)'
                }
                isLoading={productState?.isLoading}
                padding={2}
                isDisabled={!input?.merchandise_name || !base64_photos[0]}
                type="submit"
              >
                <Text
                  fontSize="16px"
                  fontWeight="400"
                  lineHeight="26px"
                  color={
                    !input?.merchandise_name || !base64_photos[0]
                      ? 'black'
                      : 'white'
                  }
                >
                  Save
                </Text>
              </Button>
            </Center>
          </Box>
        </form>
      </Flex>
    </>
  );
}
