import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const storeWallet = createAsyncThunk(
  'wallet/storeWallet',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/wallet/coin/store`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    });
    const response = await res.json();

    if (response?.status_code == 201) {
      return response?.data;
    }

    return {};
  }
);

export const deleteWallet = createAsyncThunk(
  'wallet/deleteWallet',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/wallet/coin/delete`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    });
    const response = await res.json();

    return response?.data;
  }
);

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    wallet: {},
    isLoading: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(storeWallet.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(storeWallet.fulfilled, (state, action) => {
      state.isLoading = false;

      state.wallet = action.payload;
    });
    builder.addCase(storeWallet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteWallet.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteWallet.fulfilled, (state, action) => {
      state.isLoading = false;

      state.wallet = action.payload;
    });
    builder.addCase(deleteWallet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default walletSlice.reducer;
