import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const getAllCategories = createAsyncThunk(
  'category/getAllCategories',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;
    const res = await fetch(`${apiUrl}/product/category/index`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    const response = await res.json();

    if (response?.status_code == 200) {
      return response?.data?.data;
    }

    return {};
  }
);

export const searchCategories = createAsyncThunk(
  'category/searchCategories',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/product/category/search/index`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
      },
      body: formData,
    });
    const response = await res.json();

    if (response?.status_code == 200) {
      return response?.data;
    }

    return thunkAPI.rejectWithValue('Invalid Search');
  }
);

export const createCategories = createAsyncThunk(
  'category/createCategories',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/product/category/store`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
      },
      body: formData,
    });
    const response = await res.json();

    if (response?.status_code == 201) {
      return response?.data;
    }

    return thunkAPI.rejectWithValue('Invalid Create');
  }
);

export const selectCategoryAction = createAsyncThunk(
  'category/selectCategoryAction',
  async (data, thunkAPI) => {
    return data;
  }
);

export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categories: {},
    searchedCategories: [],
    selectedCategory: '',
    isLoading: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllCategories.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.isLoading = false;

      state.categories = action.payload;
    });
    builder.addCase(getAllCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(searchCategories.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(searchCategories.fulfilled, (state, action) => {
      state.isLoading = false;

      state.searchedCategories = action.payload;
    });
    builder.addCase(searchCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(createCategories.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createCategories.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(selectCategoryAction.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(selectCategoryAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedCategory = action.payload;
    });
    builder.addCase(selectCategoryAction.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default categorySlice.reducer;
