import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const getMyStore = createAsyncThunk(
  'auth/getMyStore',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const res = await fetch(`${apiUrl}/store/me`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
      },
    });
    const response = await res.json();

    if (response?.status_code == 200) {
      return response?.data?.data;
    }

    return {};
  }
);

export const showAStore = createAsyncThunk(
  'auth/showAStore',
  async (data, thunkAPI) => {
    const res = await fetch(`${apiUrl}/public/store/show?id=${data?.id}`, {
      method: 'GET',
    });
    const response = await res.json();

    if (response?.status_code == 200) {
      return response?.data;
    }

    return {};
  }
);

export const storeSlice = createSlice({
  name: 'store',
  initialState: {
    myStores: [],
    store: {},
    isLoading: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMyStore.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getMyStore.fulfilled, (state, action) => {
      state.isLoading = false;

      state.myStores = action.payload;
    });
    builder.addCase(getMyStore.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(showAStore.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(showAStore.fulfilled, (state, action) => {
      state.isLoading = false;
      state.store = action.payload;
    });
    builder.addCase(showAStore.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default storeSlice.reducer;
