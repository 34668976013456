import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Divider,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  CardBody,
  ButtonGroup,
  CardFooter,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Switch,
  Heading,
  Image,
  Skeleton,
  SkeletonText,
  SkeletonCircle,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import bot from '../assets/img/bot.jpeg';
import money from '../assets/img/money_wallpaper.jpeg';

import { useDispatch, useSelector } from 'react-redux';
import { register } from '../redux/slices/auth';
import {
  filterStoreProduct,
  getMyProducts,
  singleProduct,
} from '../redux/slices/product';
import { useParams } from 'react-router-dom';
import { getMyCart } from '../redux/slices/cart';

import ArrowSvg from '../assets/svg/arrow-left.svg';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import SuccessfulSvg from '../assets/svg/success.svg';
import PendingSvg from '../assets/svg/pending.svg';
import FailedSvg from '../assets/svg/failed.svg';

import EmptyCategorySvg from '../assets/svg/empty-category.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';

import { showAStore } from '../redux/slices/store';
import {
  autoEllipses,
  formatDate,
  formatNumberWithCommas,
  getDateWithTime,
  ucfirst,
} from '../helpers/helpers';
import { toggleDarkMode } from '../redux/slices/settings';
import { filterMyStoreOrder, singleOrder } from '../redux/slices/orders';

import { MdSearch } from 'react-icons/md';

export default function MyStoreOrders() {
  const { colorMode, toggleColorMode } = useColorMode();

  const location = useLocation();

  const bg = useColorModeValue('white', 'black');
  const color = useColorModeValue('white', 'gray.800');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  const authState = useSelector(state => state.auth);

  const cartState = useSelector(state => state.cart);

  const orderState = useSelector(state => state.order);

  const settingsState = useSelector(state => state.settings);

  const [foodMenu, setFoodMenu] = React.useState([]);
  const [activeMenu, setActiveMenu] = React.useState('unshipped');

  const { id } = useParams();

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    // Get food menu to state

    setIsLoading(true);
    dispatch(
      filterMyStoreOrder({
        status: activeMenu,
        business_id: authState?.team,
      })
    );
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [activeMenu]);

  const viewOrder = item => {
    dispatch(singleOrder(item));
    navigate('/order-details');
  };

  const viewMore = () => {
    dispatch(
      filterMyStoreOrder({
        status: activeMenu,
        business_id: authState?.team,
        page: orderState?.storeOrder?.current_page + 1,
      })
    );
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        //height={'100vh'}
        // pt={{ base: '120px', md: '75px' }}
        pb={{ base: '90px', md: '20px' }}
      >
        <Card
          p="10px"
          // bgColor={bg}
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/dashboard`)}
              variant="ghost"
              alignSelf={'flex-start'}
              _hover={{ bg: 'transparent' }}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>NOTSTORE</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        {/* <Card width={'90%'} my={5} mx={{ base: '20px', md: '20px' }}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            pl={4}
          >
            <MdSearch size={20} color="gray.300" />

            <Input
              type="number"
              height={'50px'}
              pl={5}
              variant="unstyled"
              fontSize={'12px'}
              lineHeight={'18px'}
              fontWeight={'500'}
              name="search"
              borderColor={'rgba(208, 207, 207, 1)'}
              width={{ sm: '100%', md: '100%' }}
              placeholder="Search..."
            />
          </Box>
        </Card> */}

        <Flex
          px={{ base: '20px', md: '20px' }}
          pt={{ base: '20px', md: '20px' }}
          mb={{ base: '20px', md: '20px' }}
          direction={'row'}
          overflowX="auto"
          maxWidth="100%"
        >
          {['unshipped', 'shipped', 'delivered', 'closed'].map((status, i) => (
            <Box
              key={i}
              borderBottomColor={
                status === activeMenu ? 'rgba(36, 107, 253, 1)' : 'transparent'
              }
              borderBottomWidth={'4px'}
              borderBottomLeftRadius={'2px'}
              borderBottomRightRadius={'2px'}
              paddingBottom={'5px'}
              marginRight={'20px'}
              onClick={() => setActiveMenu(status)}
            >
              <Text
                color={
                  status === activeMenu
                    ? 'rgba(36, 107, 253, 1)'
                    : 'rgba(155, 155, 155, 1)'
                }
                fontWeight={'500'}
                fontSize={'15px'}
                whiteSpace={'nowrap'}
              >
                {ucfirst(status)}
              </Text>
            </Box>
          ))}
        </Flex>

        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 1fr)',
          }} // 1 column on mobile, 3 columns on medium screens and above
          gap={{ base: 4, md: 8 }}
          px={{ base: '20px', md: '20px' }}
          pb={{ base: '20px', md: '20px' }}
        >
          {isLoading ? (
            <>
              <Skeleton
                noOfLines={1}
                mb={2}
                spacing="4"
                height={'50px'}
                width="100%"
              />

              <Skeleton
                noOfLines={1}
                mb={2}
                spacing="4"
                height={'50px'}
                width="100%"
              />

              <Skeleton
                noOfLines={1}
                mb={2}
                spacing="4"
                height={'50px'}
                width="100%"
              />
            </>
          ) : orderState?.storeOrder &&
            orderState?.storeOrder?.data &&
            orderState?.storeOrder?.data.length > 0 ? (
            orderState?.storeOrder?.data.map((item, index) => (
              <React.Fragment style={{ alignItems: 'center' }} key={index}>
                <Heading mb={4}>{formatDate(item?.date)}</Heading>
                {item?.orders.length > 0 &&
                  item?.orders.map((order, i) => (
                    <Card
                      key={i}
                      width={'100%'}
                      py={4}
                      px={6}
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      alignSelf={'center'}
                      alignItems={'center'}
                      mb={3}
                      onClick={() => viewOrder(order)}
                    >
                      <Flex flexDirection={'row'}>
                        <Text
                          fontSize={'43px'}
                          lineHeight={'40px'}
                          fontWeight={'400'}
                          mr={2}
                          color={
                            order?.order_paid
                              ? 'rgba(0, 210, 32, 1)'
                              : 'rgba(229, 20, 0, 1)'
                          }
                        >
                          {getDateWithTime(order?.created_at).hours12}
                        </Text>
                        <Flex alignItems={'center'} direction={'column'}>
                          <Text
                            fontSize={'14px'}
                            lineHeight={'20px'}
                            fontWeight={'400'}
                            color={
                              order?.order_paid
                                ? 'rgba(0, 210, 32, 1)'
                                : 'rgba(229, 20, 0, 1)'
                            }
                          >
                            {getDateWithTime(order?.created_at).minutes}
                          </Text>
                          <Text
                            fontSize={'14px'}
                            lineHeight={'20px'}
                            fontWeight={'400'}
                            color={'rgba(142, 139, 139, 0.8)'}
                          >
                            {getDateWithTime(order?.created_at).ampm}
                          </Text>
                        </Flex>
                      </Flex>

                      <Flex direction={'column'}>
                        <Text
                          fontSize={'14px'}
                          lineHeight={'18px'}
                          fontWeight={'400'}
                        >
                          {formatDate(order?.created_at)}
                        </Text>

                        <Text
                          fontSize={'14px'}
                          lineHeight={'18px'}
                          fontWeight={'400'}
                          color={'rgba(142, 139, 139, 0.8)'}
                        >
                          {autoEllipses(order?.recipient_email)}
                        </Text>
                      </Flex>

                      <Text
                        fontSize={'14px'}
                        lineHeight={'18px'}
                        fontWeight={'400'}
                      >
                        {formatNumberWithCommas(order?.order_amount)}{' '}
                        <span style={{ color: 'rgba(142, 139, 139, 0.8)' }}>
                          {order?.currency ?? 'USD'}
                        </span>
                      </Text>
                    </Card>
                  ))}
              </React.Fragment>
            ))
          ) : (
            <Center
              mt={'20vh'}
              px={{ base: '20px', md: '20px' }}
              flexDirection={'column'}
            >
              <Image src={EmptyCategorySvg} />

              <Text
                fontSize={'16px'}
                lineHeight={'24px'}
                fontWeight={'400'}
                mt={6}
                textAlign={'center'}
              >
                Seems like you haven’t sold any products yet.
              </Text>
            </Center>
          )}

          {orderState?.storeOrder?.current_page !==
            orderState?.storeOrder?.last_page &&
            !isLoading &&
            (orderState?.storeOrder?.data ?? []).length > 0 && (
              <Button
                width={'100%'}
                borderRadius={'16px'}
                bgColor={'rgba(215, 226, 244, 1)'}
                alignSelf={'center'}
                height={'58px'}
                onClick={() => viewMore()}
              >
                <Text
                  fontSize={'16px'}
                  lineHeight={'22.4px'}
                  fontWeight={'700'}
                  color={'rgba(36, 107, 253, 1)'}
                >
                  View More
                </Text>
              </Button>
            )}
        </Grid>
      </Flex>
    </>
  );
}
