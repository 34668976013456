import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  Heading,
  Avatar,
  Image,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import ArrowSvg from '../assets/svg/arrow-left.svg';

import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import DropdownArrowSvg from '../assets/svg/dropdown-arrow.svg';

import DropupArrowSvg from '../assets/svg/dropup-arrow.svg';

import BlackDropdownArrowSvg from '../assets/svg/blackdropdown.svg';

import BlackDropupArrowSvg from '../assets/svg/blackdropup.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { toggleDarkMode } from '../redux/slices/settings';
import { Player } from '@lottiefiles/react-lottie-player';

export default function TermsConditionsPage() {
  const { colorMode, toggleColorMode } = useColorMode();

  const text = useColorModeValue('white', 'gray.800');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { state } = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const [show, setShow] = React.useState('');

  const allFaq = [
    {
      title: 'Store Creation',
      description:
        'By using Notstore you agree that creating and managing your store is entirely your responsibility.',
    },
    {
      title: 'Product Listings',
      description:
        'Ensure that your product descriptions and images are accurate. Notstore is not responsible for the quality or legality of any items.',
    },
    {
      title: 'Payments',
      description:
        'Payments are processed through your Telegram wallet. Notstore does not handle or manage payments directly.',
    },
    {
      title: 'Fees',
      description:
        'Notstore may charge a percentage on each sale. You will be notified about any fees deducted from earnings.',
    },
    {
      title: 'Transactions',
      description:
        'Notstore does not guarantee delivery of items. Buyers should only purchase from stores they trust.',
    },
    {
      title: 'Liability',
      description:
        'Notstore is not liable for any disputes, damages, or losses arising from transactions between buyers and sellers.',
    },
    {
      title: 'Termination',
      description:
        'Notstore  reserves the right to suspend or terminate any store without prior notice if any suspicious activity is detected',
    },
  ];

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        pb={{ base: '90px', md: '20px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={7}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() =>
                id
                  ? navigate(`/payment-method/${id}`, {
                      state: state,
                    })
                  : navigate('/create-store')
              }
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>Notstore Terms and Conditions</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Flex direction={'column'} px={{ base: '20px', md: '20px' }}>
          {allFaq.map((item, index) => (
            <Card
              p={4}
              alignItems={'center'}
              borderRadius={'8px'}
              flexDirection={'column'}
              mb={'10px'}
              onClick={() => (show == index ? setShow('') : setShow(index))}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'row'}
                width={'100%'}
              >
                <Text
                  fontSize={'12px'}
                  lineHeight={'16.8px'}
                  fontWeight={'500'}
                  mb={'4px'}
                >
                  {item?.title}
                </Text>

                <Image
                  src={
                    show == index
                      ? colorMode == 'dark'
                        ? DropdownArrowSvg
                        : BlackDropdownArrowSvg
                      : colorMode == 'dark'
                      ? DropupArrowSvg
                      : BlackDropupArrowSvg
                  }
                  mr={3}
                />
              </Box>

              {show == index && (
                <>
                  <Text fontSize={'9px'} lineHeight={'12.6px'} mb={3} mt={6}>
                    {item?.description}
                  </Text>
                </>
              )}
            </Card>
          ))}
        </Flex>
      </Flex>
    </>
  );
}
