import React from 'react';
import {
  Box,
  Text,
  Flex,
  IconButton,
  useColorModeValue,
  useColorMode,
  Image,
  Center,
} from '@chakra-ui/react';
import { FaHome, FaSearch, FaUser } from 'react-icons/fa';

import DarkHomeIcon from '../assets/svg/dark-home-icon.svg';
import HomeSvg from '../assets/svg/home-icon.svg';
import ActiveHomeSvg from '../assets/svg/active-home.svg';

import DarkCommunitySvg from '../assets/svg/dark-community.svg';
import CommunitySvg from '../assets/svg/community.svg';
import ActiveCommunitySvg from '../assets/svg/active-community.svg';

import DarkCreateSvg from '../assets/svg/dark-create.svg';
import CreateSvg from '../assets/svg/create.svg';

import DarkCartSvg from '../assets/svg/dark-cart-tab.svg';
import CartSvg from '../assets/svg/cart-tab.svg';
import ActiveCartSvg from '../assets/svg/active-cart.svg';

import DarkProfileSvg from '../assets/svg/dark-profile.svg';
import ProfileSvg from '../assets/svg/profile.svg';
import ActiveProfileSvg from '../assets/svg/active-profile.svg';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const FixedBottomTab = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const cartState = useSelector(state => state.cart);

  const location = useLocation();

  const navigate = useNavigate();

  const getIdFromPathname = pathname => {
    const parts = pathname.split('/');
    return parts.length > 2 ? parts[2] : null;
  };

  const getActiveFromPathname = pathname => {
    const parts = pathname.split('/');
    return parts.length > 2 ? parts[1] : null;
  };

  const id = getIdFromPathname(location.pathname);

  const bg = useColorModeValue('white', 'gray.700');

  const HomeIcon = () => {
    if (getActiveFromPathname(location.pathname) === 'store') {
      return <Image src={ActiveHomeSvg} />;
    }
    return <Image src={colorMode === 'light' ? HomeSvg : DarkHomeIcon} />;
  };

  const CommunityIcon = () => {
    if (getActiveFromPathname(location.pathname) === 'community') {
      return <Image src={ActiveCommunitySvg} />;
    }

    return (
      <Image src={colorMode === 'light' ? CommunitySvg : DarkCommunitySvg} />
    );
  };

  const CreateIcon = () => {
    return <Image src={colorMode === 'light' ? CreateSvg : DarkCreateSvg} />;
  };

  const CartIcon = () => {
    if (getActiveFromPathname(location.pathname) === 'cart') {
      return <Image src={ActiveCartSvg} />;
    }

    return <Image src={colorMode === 'light' ? CartSvg : DarkCartSvg} />;
  };

  const ProfileIcon = () => {
    if (
      getActiveFromPathname(location.pathname) === 'profile' ||
      getActiveFromPathname(location.pathname) === 'customer-order-details'
    ) {
      return <Image src={ActiveProfileSvg} />;
    }
    return <Image src={colorMode === 'light' ? ProfileSvg : DarkProfileSvg} />;
  };

  return (
    <Box
      position="fixed"
      bottom={0}
      width="100%"
      bg={bg}
      p={3}
      boxShadow="md"
      zIndex={1000}
      borderTopLeftRadius={'10px'}
      borderTopRightRadius={'10px'}
    >
      <Flex justify="space-around">
        <IconButton
          aria-label="Home"
          icon={HomeIcon()}
          variant="ghost"
          fontSize="24px"
          onClick={() => navigate(`/store/${id}`)}
          _hover={{ bg: 'transparent' }}
        />
        <IconButton
          aria-label="Community"
          icon={CommunityIcon()}
          variant="ghost"
          fontSize="24px"
          _hover={{ bg: 'transparent' }}
          onClick={() => navigate(`/community/${id}`)}
        />

        <IconButton
          aria-label="Create"
          icon={CreateIcon()}
          variant="ghost"
          fontSize="24px"
          _hover={{ bg: 'transparent' }}
          onClick={() => navigate(`/new/${id}`)}
        />

        {cartState?.myCart.length > 0 ? (
          <Box position={'relative'}>
            {cartState?.myCart.length > 0 && (
              <Center
                justifyContent={'center'}
                alignItems={'center'}
                top={'-19%'}
                right={'10%'}
                position={'absolute'}
                height={5}
                width={5}
                borderRadius={'100%'}
                bgColor={'wheat'}
              >
                <Text fontSize={'12px'} color={'teal'} alignSelf={'center'}>
                  {cartState?.myCart.length ?? '0'}
                </Text>
              </Center>
            )}

            <IconButton
              aria-label="Cart"
              icon={CartIcon()}
              variant="ghost"
              fontSize="24px"
              _hover={{ bg: 'transparent' }}
              onClick={() => navigate(`/cart/${id}`)}
            />
          </Box>
        ) : (
          <IconButton
            aria-label="Cart"
            icon={CartIcon()}
            variant="ghost"
            fontSize="24px"
            _hover={{ bg: 'transparent' }}
            onClick={() => navigate(`/cart/${id}`)}
          />
        )}

        <IconButton
          aria-label="Profile"
          icon={ProfileIcon()}
          onClick={() => navigate(`/profile/${id}`)}
          variant="ghost"
          fontSize="24px"
          _hover={{ bg: 'transparent' }}
        />
      </Flex>
    </Box>
  );
};

export default FixedBottomTab;
