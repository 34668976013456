import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const getCountry = createAsyncThunk(
  'location/getCountry',
  async thunkAPI => {
    try {
      const res = await fetch(
        `${apiUrl}/region/earth/index?category=countries&properties=1`,
        {
          method: 'GET',
        }
      );
      const response = await res.json();

      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getState = createAsyncThunk(
  'location/getState',
  async (data, thunkAPI) => {
    try {
      const res = await fetch(`${apiUrl}/region/earth/sort/index`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(data),
      });

      const response = await res.json();

      return response?.data?.states;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getCity = createAsyncThunk(
  'location/getCity',
  async (data, thunkAPI) => {
    try {
      const res = await fetch(`${apiUrl}/region/earth/sort/index`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const response = await res.json();

      return response?.data?.cities;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const locationSlice = createSlice({
  name: 'location',
  initialState: {
    country: [],
    state: [],
    city: [],
    isLoading: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCountry.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getCountry.fulfilled, (state, action) => {
      state.isLoading = false;
      state.country = action.payload;
    });
    builder.addCase(getCountry.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(getState.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getState.fulfilled, (state, action) => {
      state.isLoading = false;
      state.state = action.payload;
    });
    builder.addCase(getState.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(getCity.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getCity.fulfilled, (state, action) => {
      state.isLoading = false;
      state.city = action.payload;
    });
    builder.addCase(getCity.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default locationSlice.reducer;
